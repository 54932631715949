import { WppSelect, WppListItem } from "@wppopen/components-library-react";

export const DropSelectWppOpen = (props) => {
  const {type, title, items, selection, setSelection, className} = props;

  return (
    <WppSelect
      placeholder={selection?.length ? selection : 'Choose options'}
      type={type}
      labelConfig={{ text: title }}
      value={selection}
      onWppChange={e =>setSelection(e.detail.value)}
      withSearch={true}
      withFolder={true}
      className={className}
      required
    >
      {items?.length > 0 && items.map((item, index) => {
        return (
          <WppListItem key={index} value={item}>
            <p className={className} slot="label">{item}</p>
          </WppListItem>
        )
      })}
    </WppSelect>
  )
}
export default DropSelectWppOpen;