import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import { ModalSpinner } from '../components/ModalSpinner';
import ReactGA from 'react-ga4';
import {
  WppButton,
  WppAccordion,
  WppTypography,
  WppCard,
  WppIconPlus,
  WppIconEdit,
} from '@wppopen/components-library-react';
import { jwtDecode } from 'jwt-decode';
import ModalCreateProject from '../components/ModalCreateProject';
import useAuthUser from '../components/AuthUser';
import { levelNames } from '../okta/OktaRoles';
import { IsRendered } from '../utils/RolesUtils';
import '../stylesheets/HomePage.css';

const HomePage = (props) => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const userInfo = useAuthUser();
  const accessToken = oktaAuth.getIdToken();
  const decodedToken = jwtDecode(accessToken);
  const userPermissions = decodedToken.groupsFusion;
  const baseApi = process.env.REACT_APP_API_ENDPOINT + '/insights/v2';
  const { title } = props;
  const { authState } = useOktaAuth();
  const [showHome, setShowHome] = useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectTitle, setProjectTitle] = React.useState('');
  const [ctaTitle, setCtaTitle] = React.useState('');
  const [currentCards, setCurrentCards] = useState([]);
  const [users, setUsers] = React.useState([]);
  const [newProject, setNewProject] = useState({});
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [projectData, setProjectData] = useState([{}]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currProjectId, setCurrProjectId] = useState(null);
  const globalCard = {
    name: 'Global',
    insights: [
      {
        name: 'Retail Media Capabilities',
        subhead: 'Analysis of 185 retail media platforms, weighted and scored by our staff.',
      },
    ],
  };
  const { level10 } = levelNames;

  const handleNewProjectClick = () => {
    setModalTitle('Create New Project');
    setCtaTitle('Create Project');
    setIsModalOpen(true);
  };

  const handleEditProject = (e) => {
    const currentProject = projectData.find(
      (project) => project.name === e.target.closest('.wpp-accordion').querySelector('.wpp-typography').innerText
    );
    setCurrProjectId(currentProject.projectId);
    let currentUsers = [];
    try {
      currentProject.users.forEach((user) => {
        currentUsers.push({ email: user.email, role: user.role });
      });
    } catch (e) {}
    setModalTitle('Edit Project');
    setProjectTitle(currentProject.name);
    setUsers(currentUsers);
    setCtaTitle('Save Changes');
    setIsModalOpen(true);
  };

  const handleCardClick = (project, insight) => {
    project.name !== 'Global'
      ? navigate(
          `/insight?projectId=${project.projectId}&insightId=${insight.insightId}&flowsetId=${insight.flow.flowSetId}`
        )
      : navigate(`/insight/rtmv2`);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: title,
    });
    setTimeout(() => {
      setShowHome(true);
    }, 10);
  }, [title]);

  const fetchData = (endpoint, method, body) => {
    setIsDataLoading(true);
    fetch(`${baseApi}${endpoint}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      },
      body: body && JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        try {
          data.data.unshift(globalCard);
        } catch (e) {}
        endpoint === '/project/getall' && setProjectData(data.data);
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    fetchData('/project/getall', 'GET', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTrigger]);

  return (
    <>
      {authState?.isAuthenticated && (
        <div className={showHome ? 'fade-in h-full' : 'invisible'}>
          <Header title="Fusion.Insights" />
          <div className={'flex w-full flex-row h-full'}>
            <SideBar active={'insights'} userPermissions={userPermissions} />
            <div className={'flex pb-32 flex-col items-center w-full h-full ml-[80px] bg-gray-50'}>
              <div
                className={
                  'text-4xl sm:text-4xl md:text-6xl lg:text-6xl 2xl:text-6xl text-gray-500 mt-12 sm:mt-12 md:mt-28 mx-4'
                }
              >
                Welcome to <span className={'text-blue-700 sm:bg-blue-100 sm:px-4 sm:py-2'}>Insights</span>
              </div>
              <div className={'text-xl text-gray-600 mt-4 hidden sm:block'}>Add a new project to get started!</div>
              <div
                className={'flex flex-col w-full justify-start px-4 pb-24 sm:px-4 md:px-16 lg:px-24 mt-12 sm:mt-12 md:mt-24 bg-gray-50'}
              >
                {IsRendered(level10, userPermissions) && (
                  <div>
                    <WppButton onClick={handleNewProjectClick}>Create New Project</WppButton>
                  </div>
                )}

                {isDataLoading && <ModalSpinner />}

                <div className={'mt-4'}>
                  {projectData &&
                    projectData.map((project, key) => {
                      return (
                        <div key={key}>
                          <WppAccordion size="m" expanded={key === 0 && true}>
                            <WppTypography type="m-strong" slot="header">
                              <div className="flex w-full my-2">
                                <div className="flex w-full">{project.name}</div>
                                {IsRendered(level10, userPermissions) && (
                                  <>
                                    {project.name !== 'Global' && (
                                      <div className="absolute right-[12px] top-[4px] border-[1px] px-2 py-1 flex rounded-[8px]">
                                        <WppIconEdit onClick={handleEditProject} />
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </WppTypography>
                            <div className={'ml-[-12px] my-4 w-full'}>
                              <div
                                className={
                                  'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4'
                                }
                              >
                                {project['insights'] &&
                                  project['insights'].map((insight, key) => {
                                    return (
                                      <WppCard
                                        size={'2xl'}
                                        className={'cursor-pointer'}
                                        onClick={() => handleCardClick(project, insight)}
                                        key={key}
                                      >
                                        <div className={'min-h-[124px] flex flex-col relative'}>
                                          <div className={'flex flex-row items-center'}>
                                            <div className={'text-[1.2em] font-bold text-gray-700 pr-2'}>
                                              {insight.name}
                                            </div>
                                          </div>
                                          <div>
                                            <div className={'text-l text-gray-700 mt-2'}>{insight.subhead}</div>
                                          </div>
                                        </div>
                                      </WppCard>
                                    );
                                  })}
                                {project.name !== 'Global' && (
                                  <>
                                    {IsRendered(level10, userPermissions) && (
                                      <WppCard
                                        size={'2xl'}
                                        className={'cursor-pointer'}
                                        onClick={() => {
                                          navigate(`/insight?projectId=${project.projectId}`);
                                        }}
                                      >
                                        <div className={'flex w-full items-center justify-center min-h-[124px]'}>
                                          <WppIconPlus className={'text-blue-700'} />
                                          <div className={'text-blue-700 font-bold ml-1'}>Add Insight</div>
                                        </div>
                                      </WppCard>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </WppAccordion>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <ModalCreateProject
            modalTitle={modalTitle}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            projectTitle={projectTitle}
            setProjectTitle={setProjectTitle}
            ctaTitle={ctaTitle}
            currentCards={currentCards}
            setCurrentCards={setCurrentCards}
            users={users}
            setUsers={setUsers}
            projectData={projectData}
            setProjectData={setProjectData}
            newProject={newProject}
            setNewProject={setNewProject}
            fetchData={fetchData}
            baseApi={baseApi}
            userEmail={userInfo?.email}
            userName={userInfo?.name}
            setFetchTrigger={setFetchTrigger}
            currProjectId={currProjectId}
          />
        </div>
      )}
    </>
  );
};

export default HomePage;
