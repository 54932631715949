/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import useAuthUser from "../../../../components/AuthUser";
import { FormProvider, useForm } from "react-hook-form";
import SelectRFI from './Steps/SelectRFI';
import FlowName from './Steps/FlowName';
import SelectPartners from './Steps/SelectPartners';
import ReviewInformation from './Steps/ReviewInformation';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepButton } from "@mui/material";
import { WppButton } from '@wppopen/components-library-react'
import { FlowValidationSchema } from '../../Utils/validation';
import { yupResolver } from "@hookform/resolvers/yup";
import { Styles } from './styles';
import { FLOW_STEPS } from '../../../../pages/Flow/Utils/constants';
import SnackbarComponent from '../../../../components/Snackbar';
import { ModalSpinner } from "../../../../components/ModalSpinner";

const FlowStepperStyle = Styles.FlowStepperStyle;

const reformatDateToDB = (date) => {
  return `${date.substring(6, 10)}-${date.substring(0, 2)}-${date.substring(3, 5)}`;
}

const FlowStepper = (props) => {

  const { handleCloseModal, rfiCardsData, setFlowCreated, flowCardsData, flowCardData } = props;

  const baseApi = process.env.REACT_APP_API_ENDPOINT;
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const userInfo = useAuthUser();

  const [activeStep, setActiveStep] = useState(0);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [rfiId, setRfiId] = useState(flowCardData?.rfiId);
  const [partnersResponders, setPartnersResponders] = useState([]);
  const [isSpinnerSpinning, setIsSpinnerSpinning] = useState(false);

  const currentValidationSchema = FlowValidationSchema(flowCardsData, flowCardData?.flowSetId)[activeStep];

  const screenHeight = window.innerHeight * 0.65;

  const methods = useForm({
    shouldUnregister: false,
    resolver: activeStep < 3 ? yupResolver(currentValidationSchema) : null,
    mode: "onChange"
  });
  const { handleSubmit, trigger, reset, setValue } = methods;

  useEffect(() => {
    if (flowCardData?.rfiId && activeStep === 0) {
      setValue('RFI', flowCardData?.rfiId);
      setActiveStep(prev => prev + 1)
    }
    // eslint-disable-next-line
  }, [flowCardData])

  const onSubmit = (data) => {
    setIsSpinnerSpinning(true);
    let payload = {
      "title": data.FlowName,
      "rfiId": data.RFI,
      "partners": data.PartnerAdmins?.map((partner) => {
        const selectedPartnerObj = partnersResponders.find(partnerFromList => partnerFromList.id === partner.partnerId);
        const responderName = selectedPartnerObj.responders.find(responder => responder.responderId === partner.responder)?.name
        return {
          "partner": partner.partnerId,
          "responder": partner.responder,
          "responderEmailAddress": partner.email,
          "responderName": responderName,
          "flowId": partner?.flowId ?? null
        }
      }),
      "flowAdmins": data.FlowAdmins,
      "accessWindow": [reformatDateToDB(data.accessWindowStartDate), reformatDateToDB(data.accessWindowEndDate)],
      "createdBy": {
        "name": userInfo?.name,
        "emailAddress": userInfo?.email
      }
    }

    const url = flowCardData?.flowSetId ?? 'create_flow';
    fetch(`${baseApi}/flow/${url}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          let success = { data };
          setFlowCreated?.(success);
          setIsSpinnerSpinning(false);
          handleNext();
        }
      }
      )
      .catch((error) => {
        handleNext();
        setSnackMessage(error.message);
        setSnackOpen(true);
      });
  };

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
    handleCloseModal();
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <SelectRFI rfiId={rfiId} setRfiId={setRfiId} rfiCardsData={rfiCardsData} />;
      case 1:
        return <FlowName 
          flowName={flowCardData?.title}
          flowAdmins={flowCardData?.flowAdmins}
          accessWindow={flowCardData?.accessWindow}
        />;
      case 2:
        return <SelectPartners
          rfiId={rfiId}
          baseApi={baseApi}
          accessToken={accessToken}
          partnersResponders={partnersResponders}
          setPartnersResponders={setPartnersResponders} 
          isSpinnerSpinning={isSpinnerSpinning}
          setIsSpinnerSpinning={setIsSpinnerSpinning}
          flows={flowCardData?.flows}
        />;
      case 3:
        return <ReviewInformation 
          rfiCardsData={rfiCardsData} 
          partnersRespondersList={partnersResponders} 
          setIsSpinnerSpinning={setIsSpinnerSpinning} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '64px' }}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {FLOW_STEPS.map((label, index) => (
            <Step key={label} sx={FlowStepperStyle.stepper}>
              < StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        {isSpinnerSpinning ? (
          <ModalSpinner/>
        ) : null}

        {activeStep === FLOW_STEPS.length ? (
          <>
            <div style={FlowStepperStyle.successMessage}>
              <span style={FlowStepperStyle.successIcon}>✔</span> Submitted
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={FlowStepperStyle.stepperControls}>
              <WppButton onClick={handleReset} sx={{ mr: 1 }}>
                Close
              </WppButton>
            </Box>
          </>
        ) : (
          <FormProvider {...methods}>
              <form style={FlowStepperStyle.formContainer(screenHeight)}>
              {getStepContent()}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={FlowStepperStyle.stepperControls}>
                <WppButton
                  variant={'secondary'}
                  color="inherit"
                    disabled={activeStep === 0 || (activeStep === 1 && flowCardData)}
                  onClick={handleBack}
                  style={{ marginRight: '8px' }}
                >
                  Back
                </WppButton>
                {activeStep === FLOW_STEPS.length - 1
                  ? (
                    <WppButton onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }}>
                      Submit
                    </WppButton>
                  ) : (
                    <WppButton onClick={handleNext} sx={{ mr: 1 }}>
                      Next
                    </WppButton>
                  )
                }
              </Box>
            </form>
          </FormProvider>
        )
        }
      </Box>
      <SnackbarComponent
        snackOpen={snackOpen}
        setSnackOpen={setSnackOpen}
        message={snackMessage}
      />
    </>
  );
}

export default FlowStepper;