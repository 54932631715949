import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./okta/SecureRoute";
import { levelNames } from "./okta/OktaRoles";
import { IsRendered } from "./utils/RolesUtils";
import CustomLoginComponent from "./okta/OktaLogin";
import HomePage from "./pages/HomePage";
import InsightDetails from './pages/Insights/InsightDetails';
import InsightsRtmV2Page from "./pages/InsightsRtm/InsightsRtmPage";
import InsightsRtmV2PageLego from "./pages/InsightsRtm/InsightsRtmPage_Lego";
import InsightBidPage from "./pages/InsightBidPage";
import OktaConfig from "./okta/OktaConfig";
import FlowPage from "./pages/Flow/FlowPage";
import Portfolio from './pages/Portfolio/Portfolio';
import { jwtDecode } from "jwt-decode";

const oktaAuth = new OktaAuth(OktaConfig.oidc);

export const FusionRoutes = () => {
  const accessToken = oktaAuth.getIdToken();
  let userPermissions;
  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);
    userPermissions = decodedToken.groupsFusion;
  }

  const { level6, level10 } = levelNames;

  const navigate = useNavigate();

  const triggerLogin = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    navigate("/login", { replace: true });
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      // NOTE: customAuthHandler IS NOT BEING CALLED
      // I believe react-router-dom 6.X is conflicting with the current version of @okta/okta-react
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        {/* NOTE: THIS IS USED TO REPLICATE <Security><SecureRoute... WHICH IS NOT AVAILABLE WITH react-router-dom 6.X */}
        {/* Since onAuthRequired is not being triggered for now, CustomLoginComponent was set to the root route */}
        <Route path="/" exact element={<CustomLoginComponent />} />
        <Route path="/login/callback" exact element={<LoginCallback />} />
        <Route path="/login" exact element={<CustomLoginComponent />} />
        <Route path="/home" exact element={<RequiredAuth />}>
          <Route
            path=""
            element={<HomePage title="Insights 2.0 Home" />}
          />
        </Route>
        <Route path="/insight/rtmv2" exact element={<RequiredAuth />}>
          <Route
            path=""
            element={<InsightsRtmV2Page title="Retail Media Capabilities V2" />}
          />
        </Route>
        <Route path="/insight/lego" exact element={<RequiredAuth />}>
          <Route
            path=""
            element={<InsightsRtmV2PageLego title="Lego - Retail Media Capabilities V2" />}
          />
        </Route>
        <Route path="/insight/bid" exact element={<RequiredAuth />}>
          <Route
            path=""
            element={<InsightBidPage title="Bid Management Capabilities" />}
          />
        </Route>
        <Route path="/flow" exact element={<RequiredAuth />}>
          {userPermissions &&
            IsRendered(level6, userPermissions) && (
              <Route
                path=""
                element={
                  <FlowPage />
                }
              />
            )}
        </Route>
        <Route path="/portfolio" exact element={<RequiredAuth />}>
          {userPermissions &&
            IsRendered(level10, userPermissions) && (
              <Route
                path=""
                element={
                  <Portfolio />
                }
              />
            )}
        </Route>
        <Route path="/insight" exact element={<RequiredAuth />}>
          <Route
            path=""
            element={
              <InsightDetails />
            }
          />
        </Route>
      </Routes>
    </Security>
  );
};
