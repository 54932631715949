import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { useFormContext } from "react-hook-form"
import TrashIcon from '../../../../assets/TrashIcon';
import { Styles } from './styles'
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';

const PARTNER_ADMINS = Styles.SelectPartners.PARTNER_ADMINS;

const SelectPartners_Admin = (props) => {
  const { Controller, control, item, index, remove, errors, partnersResponders, disabled } = props;
  const { setValue } = useFormContext()

  const [responders, setResponders] = useState([]);
  const [selectedResponder, setSelectedResponder] = useState(null);

  useEffect(() => {
    if (partnersResponders?.length) {
      const selectedPartnerResponders = partnersResponders.find(partner => partner.id === item.partnerId)?.responders;
      setResponders(_.sortBy(selectedPartnerResponders, ['name']) || []);
      setSelectedResponder(item.responder);
    }
  }, [item.partnerId, item.responder, partnersResponders]);

  const handleChange = (e) => {
    const selectedPartnerResponders = partnersResponders.find(partner => partner.id === e.target.value)?.responders;
    setResponders(_.sortBy(selectedPartnerResponders, ['name']) || []);
    setSelectedResponder(null);
  }

  const handleResponderChange = (e) => {
    setSelectedResponder(e.target.value);
  }

  const handleEmailInputChange = (_, newEmails) => {
    let emails = newEmails;
    if (newEmails?.length < item?.email?.length) {
      emails = item?.email
    }
    setValue(`PartnerAdmins[${index}].email`, emails, { shouldValidate: true })
  };

  return (
    <>
      <tr style={PARTNER_ADMINS.row} key={item.id}>
        <td style={PARTNER_ADMINS.partner}>
          <Controller
            name={`PartnerAdmins[${index}].partnerId`}
            control={control}
            render={({ field: { onChange, onBlur, value, error } }) => (
              <WppSelect
                onWppChange={(e) => {
                  handleChange(e);
                  onChange(e);
                }}
                placeholder=""
                value={value}
                onBlur={onBlur}
                disabled={disabled}
                withSearch={true}
              >
                {partnersResponders?.length &&
                  partnersResponders?.map(partner => (
                    <WppListItem value={partner.id} key={partner.id}>
                      <p slot="label">{partner.name}</p>
                    </WppListItem>
                  ))}
              </WppSelect>
            )}
          />
          <div style={PARTNER_ADMINS.invalidFeedback}>{errors.PartnerAdmins?.[index]?.partnerId?.message}</div>
        </td>
        <td />
        <td style={PARTNER_ADMINS.responder}>
          <Controller
            name={`PartnerAdmins[${index}].responder`}
            control={control}
            render={({ field: { onChange, onBlur, value, error } }) => (
              <WppSelect
                onWppChange={(e) => {
                  handleResponderChange(e);
                  onChange(e);
                }}
                placeholder=""
                value={selectedResponder || ''}
                onBlur={onBlur}
                disabled={disabled}
                withSearch={true}
              >
                {responders.map(responder => (
                  <WppListItem value={responder.responderId} key={responder.responderId}>
                    <p slot="label">{responder.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            )}
          />
          <div style={PARTNER_ADMINS.invalidFeedback}>{errors.PartnerAdmins?.[index]?.responder?.message}</div>
        </td>
        <td />
        <td style={PARTNER_ADMINS.email}>
          <Controller
            control={control}
            name={`PartnerAdmins[${index}].email`}
            render={({ field: { onChange, onBlur, value, error } }) => (
              <Autocomplete
                multiple
                freeSolo
                id={`email-autocomplete-${index}`}
                options={[]}
                value={value ?? []}
                onChange={handleEmailInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    disabled={disabled}
              />
            )}
              />)}
          />
          <div style={PARTNER_ADMINS.invalidFeedback}>{errors.PartnerAdmins?.[index]?.email?.message || errors.PartnerAdmins?.[index]?.email?.find(email => email)?.message}</div>
          <div>
          </div>
        </td>
        <td />
        <td>
          {!disabled && <button onClick={() => remove(index)} style={PARTNER_ADMINS.delete}>
            <TrashIcon />
          </button>}
        </td>
      </tr>
    </>
  )
}

export default SelectPartners_Admin;