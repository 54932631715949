import React, { useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { rows, columns } from "../data/DspData";
import LinearProgress from "@mui/material/LinearProgress";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import { getColor } from './InsightsRtm/utils';
import ReactGA from "react-ga4";
import '../stylesheets/InsightBidPage.css'

const InsightBidPage = (props) => {

  const { title } = props;

  const regions=['apac', 'latam', 'emea', 'na'];
  const partners=['pacvue', 'skai', 'epsilo', 'intentwise', 'commerce_iq', 'perpetua'];
  const searchCapabilites=['bid_rules', 'optimization'];

  useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });

    setTimeout(() => {
      let muiKeyEl = document.querySelectorAll('.MuiDataGrid-main div:nth-child(3)')
      muiKeyEl.forEach((el) => {
        if (el.innerHTML === 'MUI X: Missing license key') {
          el.innerHTML = ''
        }
      })
    }, 10)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderChart = (params) => {

    let returnDom = []
    let overallValue = params.getValue(params.id, params.field  + '_overall')

    returnDom.push(
      <div key={99} className={'col-1-1 flex-align-center'}>
        {overallValue &&
          <div className={'col-1-1 flex-align-center'} style={{padding: '0 2px 0 2px'}}>
            <div className={'col-1-2 flex-align-center flex-justify-end'}>
              <span style={{paddingRight: '8px', textTransform: 'uppercase', fontSize: '10px', opacity: .4}}>overall</span>
            </div>
            <div className={'col-1-2 flex-align-center flex-justify-end'}>
              <LinearProgress
                variant="determinate"
                value={overallValue}
                sx={{
                  height: 28,
                  width: '100%',
                  borderRadius: '4px',
                  backgroundColor: '#e2e2e2',
                  margin: '6px 0 6px 0',
                  ".MuiLinearProgress-bar": {backgroundColor: getColor(overallValue)},
                }}
              />
            </div>
          </div>
        }
      </div>
    )

    if (params.row.area === 'Logos') {

      let url = params.getValue(params.id, params.field + '_logo')

      returnDom.push(
        <div key={100} className={'col-1-1 flex-align-end flex-direction-column'}>
          <img src={url} alt="" style={{width: '80px'}}/>
        </div>
      )
    }

    else if (params.row.area === 'Commercials') {

      let title = params.getValue(params.id, params.field + '_title')
      let ssa = params.getValue(params.id, params.field + '_ssa')

      returnDom.push(
        <div key={100} className={'col-1-1 flex-align-end flex-direction-column'}>
          <div className={'cell-title'}>{title}</div>
          <div className={'cell-value'}>{ssa}</div>
        </div>
      )
    }

    if (params.row.area === 'Market and Retails Coverage') {

      regions.forEach((region, index) => {

        let barValue = params.getValue(params.id, params.field + '_' + region)

        returnDom.push(
          <div className={'col-1-1 flex-direction-column flex-justify-start'} key={index}>
            {params.getValue(params.id, 'ssa') && <p>{params.getValue(params.id, 'ssa')}</p>}
            {params.getValue(params.id, params.field + '_' + region) &&
              <div className={'col-1-1 flex-align-center'} style={{padding: '0 2px 0 2px'}}>
                <div className={'col-1-2 flex-align-center flex-justify-end'}>
                  <span style={{paddingRight: '8px', textTransform: 'uppercase', fontSize: '10px', opacity: .4}}>{region}</span>
                </div>
                <div className={'col-1-2 flex-align-center flex-justify-end'}>
                  <LinearProgress
                    variant="determinate"
                    value={barValue}
                    sx={{
                      height: 6,
                      width: '100%',
                      borderRadius: '2px',
                      backgroundColor: '#e2e2e2',
                      margin: '6px 0 6px 0',
                      ".MuiLinearProgress-bar": {backgroundColor: getColor(barValue)},
                    }}
                  />
                </div>
              </div>
            }
          </div>
        )
      })
    }
    else if (params.row.area === 'Search Capabilities') {

      searchCapabilites.forEach((capabilty, index) => {

        let barValue = params.getValue(params.id, params.field + '_' + capabilty)

        returnDom.push(
          <div className={'col-1-1 flex-direction-column flex-justify-start'} key={index}>
            {params.getValue(params.id, params.field + '_' + capabilty) &&
              <div className={'col-1-1 flex-align-center'} style={{padding: '0 2px 0 2px'}}>
                <div className={'col-1-2 flex-align-center flex-justify-end'}>
                  <span style={{paddingRight: '8px', textTransform: 'uppercase', fontSize: '10px', opacity: .4}}>{capabilty}</span>
                </div>
                <div className={'col-1-2 flex-align-center flex-justify-end'}>
                  <LinearProgress
                    variant="determinate"
                    value={barValue}
                    sx={{
                      height: 6,
                      width: '100%',
                      borderRadius: '2px',
                      backgroundColor: '#e2e2e2',
                      margin: '6px 0 6px 0',
                      ".MuiLinearProgress-bar": {backgroundColor: getColor(barValue)},
                    }}
                  />
                </div>
              </div>
            }
          </div>
        )
      })
    }
    else if (params.row.area === 'DSP Capabilities') {

      searchCapabilites.forEach((capabilty, index) => {

        let barValue = params.getValue(params.id, params.field + '_' + capabilty)

        returnDom.push(
          <div className={'col-1-1 flex-direction-column flex-justify-start'} key={index}>
            {params.getValue(params.id, params.field + '_' + capabilty) &&
              <div className={'col-1-1 flex-align-center'} style={{padding: '0 2px 0 2px'}}>
                <div className={'col-1-2 flex-align-center flex-justify-end'}>
                  <span style={{paddingRight: '8px', textTransform: 'uppercase', fontSize: '10px', opacity: .4}}>{capabilty}</span>
                </div>
                <div className={'col-1-2 flex-align-center flex-justify-end'}>
                  <LinearProgress
                    variant="determinate"
                    value={barValue}
                    sx={{
                      height: 6,
                      width: '100%',
                      borderRadius: '2px',
                      backgroundColor: '#e2e2e2',
                      margin: '6px 0 6px 0',
                      ".MuiLinearProgress-bar": {backgroundColor: getColor(barValue)},
                    }}
                  />
                </div>
              </div>
            }
          </div>
        )
      })
    }
    else if (params.row.area === 'Models & Methodologies') {

      let title = params.getValue(params.id, params.field + '_title')
      let ssa = params.getValue(params.id, params.field + '_description')

      returnDom.push(
        <div key={100} className={'col-1-1 flex-align-end flex-direction-column'}>
          <div className={'cell-title'}>{title}</div>
          <div className={'cell-description'}>{ssa}</div>
        </div>
      )
    }
    else if (params.row.area === 'X Factor Differentiators') {

      let title = params.getValue(params.id, params.field + '_title')
      let ssa = params.getValue(params.id, params.field + '_description')

      returnDom.push(
        <div key={100} className={'col-1-1 flex-align-end flex-direction-column'}>
          <div className={'cell-title'}>{title}</div>
          <div className={'cell-description'}>{ssa}</div>
        </div>
      )
    }
    return (
      <div className={'col-1-1 flex-direction-column flex-center-all lalala'}>
        {returnDom}
      </div>
    )
  }

  // append renderCell to specific columns
  columns.forEach((column) => {
    if(partners.includes(column.field) === true) {
      column['renderCell'] = renderChart
    }
  })

  return (
    <>
      <SideBar/>
      <div className={'page-wrapper'}>
        <Header title="[Fusion Insights 2.0]" />
        <div className={'col-1-1 margin-t-32 flex-direction-column flex-center-all'}>
          <div className="dsp-hero flex-center-all col-1-1">
            <h2>Retail Bid Management Tech Evaluation</h2>
            <div className="overlay"></div>
          </div>
          <div className={'col-1-1 flex-direction-column padding-lr-64 bid-grid'}>
            <div style={{ height: 720, width: '100%', marginTop: '64px' }}>
              <DataGridPro
                rows={rows}
                columns={columns}
                rowHeight={160}
                headerHeight={88}
                highlightOnHover={false}
                autoHeight={false}
                initialState={{ pinnedColumns: { left: ['area']} }}
                disableSelectionOnClick
                disableColumnSelector
                disableColumnMenu
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InsightBidPage;
