import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import { Button, Box, Tab, Grid, CircularProgress, LinearProgress, Switch, Collapse }  from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FilterListIcon from '@mui/icons-material/FilterList';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Header from "../../components/Header";
import SuperSearch from "../../components/SuperSearch";
import SideBar from "../../components/SideBar";
import SnackbarComponent from '../../components/Snackbar';
import MenuGrid from './components/MenuGrid';
import Filters from './components/Filters';
import AdminMode from './components/AdminMode';
import SummaryViewCard from './components/SummaryViewCard';
import FormDialog from './components/FormDialog';
import TimeMachine from './components/TimeMachine';
import useAuthUser from "../../components/AuthUser";
import { transformData } from './utils';
import { hackGrid } from './utils';
import ReactGA from "react-ga4";
import '../../stylesheets/InsightRtmV2Page.css'
import '../../stylesheets/InsightPage.css'
import { jwtDecode } from "jwt-decode";

const Tabs =  { 
  Summary: '1',
  Detail: '2',
  Admin: '3'
}

const baseApi = process.env.REACT_APP_API_ENDPOINT+ '/insights/v2/1';

const InsightsRtmPage = (props) => {

  const { title } = props;

  const { authState, oktaAuth } = useOktaAuth();
  const userInfo = useAuthUser();
  const accessToken = oktaAuth.getIdToken();
  const decodedToken = jwtDecode(accessToken);
  const userPermissions = decodedToken.groupsFusion;

  const [value, setValue] = useState('1');
  const [liveRows, setLiveRows] = useState([]);
  const [liveColumns, setLiveColumns] = useState([]);
  const [liveColumnsOg, setLiveColumnsOg] = useState([]);
  const [isLiveRowsLoaded, setLiveRowsLoaded] = useState(false);
  const [filtersData, setFiltersData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [marketIds, setMarketIds] = useState([]);
  const [channels, setChannels] = useState([]);
  const [liveChannels, setLiveChannels] = useState([]);
  const [types, setTypes] = useState([]);
  const [liveTypes, setLiveTypes] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [cardDataOg, setCardDataOg] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [superSearchValues, setSuperSearchValues] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [gridEdited, setGridEdited] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scoredOnly, setScoredOnly] = useState(true);
  const [newRetailers, setNewRetailers] = useState([]);
  const [formType, setFormType] = useState('');
  const [timeMachineVisible, setTimeMachineVisible] = useState(false);

  // The most recently touched/edited retailer_market
  const retailerIdMarketIdRef = useRef('');
  const dataSave = useRef({});
     
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === Tabs.Detail || newValue === Tabs.Admin ) {
      hackGrid()
    }
  };

  const handleFilterChange = (event, newValue) => {
    showFilters ? setShowFilters(false) : setShowFilters(true)
  }

  const handleDialogOpen = (type) => {
    setDialogOpen(true);
    setFormType(type);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    
    ReactGA.send({hitType: "pageview", page: window.location.pathname, title: title});
    
    setGridEdited(false);

    try {
      fetch(`${baseApi}/filters`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
      })
      .then(response => response.json())
      .then(data => {
        if (data.regions?.length) {
          setFiltersData(data)
          generateFilters(data)
        }
      })
    } catch (error) {
      console.log('API FETCH ERROR: ', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userInfo?.email?.length) {
      getGridData()
      getSummaryData()
    }

    if (!newRetailers?.length) {
      setSuperSearchValues([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketIds, liveChannels, liveTypes, scoredOnly, newRetailers, userInfo])
  
  useEffect(() => {
    if (gridEdited) {
      setSnackOpen(true)
    }
    else {
      setSnackOpen(false)
    }
  }, [gridEdited])
  
  useEffect(() => {
    hackGrid()
  }, [liveRows])

  const getGridData = () => {

    if (marketIds[0] === -1 || liveChannels[0] === -1 || liveTypes[0] === -1) {
      setLiveRows([]);
      setLiveColumns([]);
      return;
    }
    setShowLoader(true)
    fetch(`${baseApi}?scoredOnly=${scoredOnly ? '1' : '0'}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
      body: JSON.stringify({
        "filters": {
          "markets": marketIds,
          "channels": liveChannels,
          "types": liveTypes,
        },
        "user": {
          "email": userInfo?.email
        },
        "access_token": oktaAuth.getIdToken,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data?.columns?.length) {
          transformData(data)
          setLiveRows(data.rows);
          setLiveColumns(data.columns);
          setLiveColumnsOg(data.columns);
          setLiveRowsLoaded(true);
          setIsAdmin(data.user.admin)
          setShowLoader(false)
        }
      })
  }

  const getSummaryData = () => {

    if (marketIds[0] === -1 || liveChannels[0] === -1 || liveTypes[0] === -1) {
      setCardData([])
      setCardDataOg([])
      return;
    }

    fetch(`${baseApi}/summary?scoredOnly=${scoredOnly ? '1' : '0'}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
      body: JSON.stringify({
        "filters": {
          "markets": marketIds,
          "channels": liveChannels,
          "types": liveTypes,
        },
        "access_token": oktaAuth.getIdToken,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data?.length) {
          setCardData(data)
          setCardDataOg(data)
        }
      })
  }

  const generateFilters = (data) => {
    let regions = []
    let markets = []
    let channels = []
    let types = []
    data.regions.forEach((region) => {
      regions.push(region.name)
    })
    data.markets.forEach((market) => {
      markets.push(market.name)
    })
    data.channels.forEach((channel) => {
      channels.push(channel.name)
    })
    data.types.forEach((type) => {
      types.push(type.name)
    })
    setRegions(regions.sort())
    setMarkets(markets.sort())
    setChannels(channels)
    setTypes(types)
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    if (_.get(oldRow, retailerIdMarketIdRef.current) !== _.get(newRow, retailerIdMarketIdRef.current)){
      dataSave.current[retailerIdMarketIdRef.current + ',' + newRow.offeringId] = newRow[retailerIdMarketIdRef.current];
      setGridEdited(true);
    }
    return updatedRow;
  };  

  const updateDB = (e) => {
    let data = [];
    _.forOwn(dataSave.current, (value, key) => {
      const field_offeringId = key.split(',');
      data.push({ field: field_offeringId[0], new_score: value, offeringId: parseInt(field_offeringId[1]) })
     });
    let user = {name: userInfo?.name, email: userInfo?.email}
    try {
      fetch(`${baseApi}/save`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
        body: JSON.stringify({
          "data": data,
          "user": user,
          "access_token": oktaAuth.getIdToken,
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.Message === 'Success') {
          setGridEdited(false);
          retailerIdMarketIdRef.current = '';
          dataSave.current = {};
        } else {
          console.log('Error: ', data);
        }
      })
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const scoredOnlyChange = (event) => {
    setScoredOnly(event.target.checked);
    if (event.target.checked) {
      setSuperSearchValues([]);
    }
  };

  return (
    <>
      {authState?.isAuthenticated
        &&
        <div style={{height: 500, width: '100%', position: 'relative'}}>
          <Header title="" />
          <SideBar active={"insights"} userPermissions={userPermissions} />
          <div className={'page-wrapper bg-gray-50'}>
            <div className={'col-1-1 pt-8 top-curve'}>
              <div className={showFilters ? 'filter-icon color-blue' : 'filter-icon'}
                onClick={handleFilterChange}>
                <FilterListIcon />
              </div>
            </div>
            <div className={showFilters ? 'filters-wrap show-filters' : 'filters-wrap hide-filters'}>
              <Filters
                regions={regions}
                markets={markets}
                channels={channels}
                types={types}
                setSuperSearchValues={setSuperSearchValues}
                setLiveChannels={setLiveChannels}
                setLiveTypes={setLiveTypes}
                setMarkets={setMarkets}
                setMarketIds={setMarketIds}
                SuperSearch={SuperSearch}
                filtersData={filtersData}
              />
              <div className={'col-1-1 margin-t-16 flex-center-all padding-lr-64 mob-only'}>
                <SuperSearch
                  setLiveColumns={setLiveColumns}
                  liveColumnsOg={liveColumnsOg}
                  setCardData={setCardData}
                  cardDataOg={cardDataOg}
                  superSearchValues={superSearchValues}
                  setSuperSearchValues={setSuperSearchValues}
                  newRetailers={newRetailers}
                />
              </div>
            </div>
            <div className={'col-1-1 margin-t-42 flex-direction-column padding-lr-64'}>
              <TabContext value={value}>
                <Box className={'mob-only'} sx={{borderBottom: 1, borderColor: 'divider', my: 0, mx: 4, display: 'flex', justifyContent: 'space-between'}}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Summary View" value="1"/>
                    <Tab label="Details View" value="2"/>
                    {isAdmin ? <Tab label="Admin Mode" value="3" /> : null}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {cardData?.length ?
                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                      {cardData.map((card, index) => (
                        <SummaryViewCard
                          key={index}
                          index={index}
                          card={card}
                        />
                      ))}
                    </Grid>
                    :
                    <div className={'col-1-1 flex-center-all'}>
                      <CircularProgress style={{marginTop: '100px'}}/>
                    </div>
                  }
                </TabPanel>
                <TabPanel value="2">
                  {showLoader && <LinearProgress className={'linear-loader'}/>}
                  <div className={'col-1-1 grid-wrap'} style={{height: '600px'}}>
                    {isLiveRowsLoaded ? 
                      <AdminMode
                        showLoader={showLoader}
                        liveRows={liveRows}
                        liveColumns={liveColumns}
                        processRowUpdate={processRowUpdate}
                        retailerIdMarketIdRef={retailerIdMarketIdRef}
                        editable={false}
                      />
                      : <CircularProgress style={{marginTop: '200px'}}/>
                    }
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  {showLoader && <LinearProgress className={'linear-loader'}/>}
                  <div className={'col-1-1 grid-wrap'} style={{height: '600px'}}>
                    <div className={"controls-wrap"}>
                      <span className={"scored-retailers"}>Scored Only</span>
                      <Switch className={'all-switch'} checked={scoredOnly} onChange={scoredOnlyChange}/>
                      <MenuGrid
                        handleDialogOpen={handleDialogOpen}
                        setTimeMachineVisible={setTimeMachineVisible}
                        isAdmin={isAdmin}
                      />
                      <Button id="saveData" disabled={!gridEdited} sx={{height: '40px'}} onClick={updateDB} variant="outlined" color="warning" startIcon={<SaveAltIcon />}>Save Changes</Button>
                    </div>
                      {isLiveRowsLoaded ?
                        <AdminMode
                          showLoader={showLoader}
                          liveRows={liveRows}
                          liveColumns={liveColumns}
                          processRowUpdate={processRowUpdate}
                          retailerIdMarketIdRef={retailerIdMarketIdRef}
                        />
                        : <CircularProgress style={{marginTop: '200px'}}/>
                      }
                  </div>
                </TabPanel>
              </TabContext>
            </div>
          </div>
          <div>
            <SnackbarComponent
              snackOpen={snackOpen}
              setSnackOpen={setSnackOpen}
              message="Changes made, don't forget to save them!"
            />
          </div>
          <Collapse orientation="horizontal" in={timeMachineVisible}>
            <TimeMachine
            timeMachineVisible={timeMachineVisible}
            setTimeMachineVisible={setTimeMachineVisible}
            />
          </Collapse>
          <FormDialog
            handleDialogClose={handleDialogClose}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            formType={formType}
            setNewRetailers={setNewRetailers}
            setScoredOnly={setScoredOnly}
            filtersData={filtersData}
            accessToken={accessToken}
          />
        </div>
      }
    </>
  );
}

export default InsightsRtmPage;
