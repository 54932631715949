import React, { useEffect, useState } from 'react';
import { WppModal } from '@wppopen/components-library-react';
import AddRetailer from './Admin/AddRetailer';
import EditRetailer from './Admin/EditRetailer';
import DeleteRetailer from './Admin/DeleteRetailer';
import SnackbarComponent from '../../../components/Snackbar';
import { ModalSpinner } from '../../../components/ModalSpinner';

const baseApi = process.env.REACT_APP_API_ENDPOINT;

const FormDialog = (props) => {

  const { 
    handleDialogClose,
    dialogOpen,
    setDialogOpen,
    formType,
    setNewRetailers,
    setScoredOnly,
    filtersData,
    accessToken,
  } = props;

  const [retailersList, setRetailersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [retailersDeleted, setRetailersDeleted] = useState(false);

  useEffect(() => {
    if (dialogOpen && (formType === 'edit' || formType === 'delete')) {
      setIsLoading(true)

      fetch(`${baseApi}/partner/fetch_rtmc`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
      })
      .then(response => response.json())
      .then(data => {
        setRetailersList(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setSnackOpen(true);
        setIsLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, retailersDeleted])

  return (
    <>

      {isLoading ? (
        <ModalSpinner />
      ) : null}
      
      <div>
        <WppModal open={dialogOpen} size="m">
          {formType === 'add' 
            &&
            <AddRetailer
              baseApi={baseApi}
              accessToken={accessToken}
              markets={filtersData.markets.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)}
              setScoredOnly={setScoredOnly}
              setDialogOpen={setDialogOpen}
              setNewRetailers={setNewRetailers}
              handleDialogClose={handleDialogClose}
              setSnackMessage={setSnackMessage}
              setSnackOpen={setSnackOpen}
              setIsLoading={setIsLoading}
            />
          }
          {formType === 'edit' 
            &&
            <EditRetailer
              baseApi={baseApi}
              accessToken={accessToken}
              retailersList={retailersList.sort((a,b) => a.retailer.toLowerCase() < b.retailer.toLowerCase() ? -1 : 1)}
              markets={filtersData.markets.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)}
              handleDialogClose={handleDialogClose}
              setSnackMessage={setSnackMessage}
              setSnackOpen={setSnackOpen}
              setIsLoading={setIsLoading}
              setScoredOnly={setScoredOnly}
              setDialogOpen={setDialogOpen}
              setNewRetailers={setNewRetailers}
            />
          }
          {formType === 'delete'
            &&
            <DeleteRetailer
              baseApi={baseApi}
              accessToken={accessToken}
              retailers={retailersList.sort((a,b) => a.retailer.toLowerCase() < b.retailer.toLowerCase() ? -1 : 1)}
              setSnackMessage={setSnackMessage}
              setSnackOpen={setSnackOpen}
              setRetailersDeleted={setRetailersDeleted}
              setNewRetailers={setNewRetailers}
              handleDialogClose={handleDialogClose}
            />
          }
        </WppModal>
      </div>
      <div>
        <SnackbarComponent
          snackOpen={snackOpen}
          setSnackOpen={setSnackOpen}
          message={snackMessage}
        />
      </div>
    </>
  );
}

export default FormDialog;